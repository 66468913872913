import React from 'react';
import { View, Pressable } from 'react-native';
import { useQuery } from '@apollo/client';
import styled from 'styled-components/native';
import { DateTime } from 'luxon';

import ExternalLink from 'app/src/elements/ExternalLink';
import { Title, Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { singleLineAddress } from 'app/src/utils/address';

import { COMMISSION_DATA } from './queries';

const Status = styled(Text)`
  font-weight: 600;
  color: ${props => props.status === 'Active' ? colors.header : 'red'}
`;

const Lookup = variables => {
  const { data } = useQuery(COMMISSION_DATA, { variables });

  if (!data) { return null; }

  const details = data?.commissionData
  const status = details?.status || 'NOT FOUND';

  const updatedAt = details && (
    DateTime.fromISO(details.updatedAt).toLocaleString(DateTime.DATETIME_SHORT)
  );

  const openJson = () => {
    const bodyBlob = new Blob([details.body], { type: 'text/json' });
    window.open(window.URL.createObjectURL(bodyBlob));
  };

  return (
    <View>
      <Title>Used Commission Record</Title>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Status status={status}>{status}</Status>
        {details && (
          <ExternalLink url={details.viewUrl}>
            <Text style={{ fontWeight: 600 }}>View on Commission Website</Text>
          </ExternalLink>
        )}
      </View>

      {details && (
        <>
          <Text>{details.name}</Text>
          <Text>{details.licenseString}</Text>
          <Text>{singleLineAddress(details)}</Text>
          <Text>{details.phone}</Text>
          <View style={{ flexDirection: 'row', gap: 12 }}>
            <Text>Record Pulled: {updatedAt}</Text>
            <Pressable onPress={openJson}>
              <Text>[View full data]</Text>
            </Pressable>
          </View>
        </>
      )}
    </View>
  );
};

const CommissionDetails = ({ dealer, usedLicense, asOf }) => {
  if (!usedLicense) {
     usedLicense = dealer.licenses.find(license => (
      !license.inactiveAt && 'UD' === license.licenseType
    ));
  }

  if (!usedLicense) { return null; }

  return (
    <Lookup {...usedLicense} asOf={asOf} />
  );
};

export default CommissionDetails;
