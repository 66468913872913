import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Link } from 'app/src/utils/routing';
import ExternalLink from 'app/src/elements/ExternalLink';
import { Text } from 'app/src/styles';

export const Container = styled(View)`
  flex-direction: row;
  background-color: black;
  padding: 10px;
  justify-content: space-between;
  height: 40px;
  z-index: 999;
`;

export const Items = styled(View)`
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const Label = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: white;
`;

const Account = styled(View)`
  position: absolute;
  right: 0px;
  top: 30px;
  background-color: black;
  padding: 20px;
  width: 200px;
  gap: 20px;
`;

export const DEALER_ITEMS = [
  {
    path: '/tags/register',
    label: 'Pre-Register Sale',
  },
  {
    path: '/pre-registrations',
    label: 'Pre-Registration History',
  },
  {
    path: '/tags',
    label: 'Tag Log',
  },
  {
    path: '/orders',
    label: 'Past Orders',
  },
  {
    url: 'https://autodealersresource.com/collections/temporary-tags',
    label: 'Order Tags',
  },
  {
    path: '/dealers',
    label: 'Dealerships',
  },
  {
    path: 'how-to',
    label: 'How To & FAQs',
  },
];

export const EMPLOYEE_ITEMS = [
  {
    path: '/',
    label: 'Home',
  },
  {
    path: '/dealers',
    label: 'Dealers',
  },
  {
    path: '/dealers/new',
    label: 'Add Dealer',
  },
  {
    path: '/orders',
    label: 'Orders',
  },
  {
    path: '/orders/new',
    label: 'New Order',
  },
  {
    path: 'tags/register',
    label: 'Register Tag',
  },
  {
    path: 'how-to',
    label: 'How To & FAQs',
  },
];

export const ADMIN_ITEMS = [
  {
    path: '/dealers/approval-status/pending',
    label: 'Pending Dealers',
  },
  {
    path: '/exports',
    label: 'Export Data',
  },
  {
    path: '/financial',
    label: 'Financial',
  },
];

export const ACCOUNT_ITEMS = [
  {
    path: '/account/profile',
    label: 'Profile',
  },
  {
    path: '/account/password',
    label: 'Change Password',
  },
  {
    path: '/account/totp',
    label: 'Two-Factor Auth',
  },
  {
    path: '/users',
    label: 'Users',
  },
  {
    action: 'logout',
    label: 'Logout',
  },
];

const Menu = () => {
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const { currentUser, activeDealers, logout } = useContext(ClientContext);

  let mainItems = [];
  if ('dealer' === currentUser?.role) {
    if (0 === activeDealers.length) {
      mainItems = [];
    } else {
      mainItems = DEALER_ITEMS;
    }
  } else if ('admin' === currentUser?.role) {
    mainItems = [...EMPLOYEE_ITEMS, ...ADMIN_ITEMS];
  } else if (currentUser) {
    mainItems = EMPLOYEE_ITEMS;
  }

  return (
    <Container>
      <Items>
        {mainItems.map(({ label, path, url }) => {
          if (url) {
            return (
              <ExternalLink key={label} url={url}>
                <Label>{label}</Label>
              </ExternalLink>
            );
          } else {
            return (
              <Link key={label} to={path}>
                <Label>{label}</Label>
              </Link>
            );
          }
        })}
      </Items>

      <Items>
        <Pressable onPress={() => setShowAccountMenu(!showAccountMenu)}>
          <Label>{currentUser.fullName} - Account</Label>

          {showAccountMenu && (
            <Account>
              {ACCOUNT_ITEMS.map(({ label, path, action }) => {
                if ('logout' === action) {
                  return (
                    <Pressable key={label} onPress={logout}>
                      <Label>Logout</Label>
                    </Pressable>
                  );
                } else {
                  return (
                    <Link key={label} to={path}>
                      <Label>{label}</Label>
                    </Link>
                  );
                }
              })}
            </Account>
          )}
        </Pressable>
      </Items>
    </Container>
  );
};

export default Menu;
