import React from 'react';

import Modal from 'app/src/elements/Modal';
import { Subtitle, Text } from 'app/src/styles';
import { cityStateZip } from 'app/src/utils/address';
import { dateShort, datetimeShort, dollars } from 'app/src/utils/formatting';

import VoidForm from './VoidForm';
import UnvoidForm from './UnvoidForm';

const Tag = ({ tag, setShow, voidTag, refetch }) => {
  const title = voidTag ? `${tag.serialNumber} Void Sale` :
    `${tag.serialNumber} Details`

  const onVoidChange = () => {
    refetch();
    setShow(false);
  };

  return (
    <Modal title={title} setShow={setShow}>
      {tag && !tag.voidAt && voidTag && (
        <VoidForm tag={tag} onVoidChange={onVoidChange} />
      )}

      <Subtitle>{tag.serialNumber}</Subtitle>
      <Text>{tag.dealer.name}</Text>

      <Text>Tag Issued {dateShort(tag.soldOn)}</Text>

      {tag.registeredAt && (
        <Text>
          Tag information saved {datetimeShort(tag.registeredAt)}
          {tag.registeredByName && `, by ${tag.registeredByName}`}
        </Text>
      )}

      {tag.submittedAt && (
        <Text>
          Tag information submitted to state {datetimeShort(tag.submittedAt)}
        </Text>
      )}

      {tag.voidAt && (
        <>
          <Subtitle>Sale marked void {datetimeShort(tag.voidAt)}</Subtitle>

          {tag.voidReason && (
            <>
              <Text>Reason: {tag.voidReason}</Text>
              <Text>Note: Reason is not transmitted to state</Text>
            </>
          )}

          {tag.submittedAt ? (
            tag.voidSubmittedAt ? (
              <Text>
                Void submitted to state{' '}
                {
                  tag.voidSubmittedAt ?
                    datetimeShort(tag.voidSubmittedAt) : 'Pending'
                }
              </Text>
            ) : (
              <>
                <Text>Registration was not submitted to state before void</Text>
                {tag.canUnvoid && (
                  <UnvoidForm tag={tag} onVoidChange={onVoidChange} />
                )}
              </>
            )
          ) : (
            <>
              <Text>
                Voided before pre-registration information sent to state, so
                neither pre-registration or void information will be submitted.
              </Text>
              {tag.canUnvoid && (
                <UnvoidForm tag={tag} onVoidChange={onVoidChange} />
              )}
            </>
          )}
        </>
      )}

      <Subtitle>Vehicle</Subtitle>
      <Text>{tag.vin}</Text>
      <Text>{tag.year} {tag.makeLabel} {tag.model} {tag.style}</Text>
      <Text>{tag.color}</Text>
      <Text>{'M' === tag.type ? 'Motorcycle' : 'Automobile'}</Text>
      {tag.purchasePrice && (
        <Text>Purchase Price: {dollars(tag.purchasePrice)}</Text>
      )}
      {tag.tradeInValue && (
        <Text>Trade-In Value: {dollars(tag.tradeInValue)}</Text>
      )}

      <Subtitle>Purchaser</Subtitle>
      <Text>{tag.purchaser}</Text>
      <Text>{tag.street}</Text>
      {tag.unitNumber && (
        <Text>{tag.unitType} {tag.unitNumber}</Text>
      )}
      <Text>{cityStateZip(tag)}</Text>
      <Text>{tag.phone}</Text>
      <Text>{tag.email}</Text>
    </Modal>
  );
};

export default Tag;
