import React, { useContext, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import { StyledTextInput } from 'app/src/elements/inputs';
import DataState from 'app/src/elements/DataState';
import { Picker } from 'app/src/elements/Picker';
import { Title, Text, Warning, WarningText } from 'app/src/styles';
import { rest } from 'app/src/utils/rest';
import { useNavigate, useParams, Link } from 'app/src/utils/routing';

import { ORDER_PRINT_SETUP, PRINT_ORDER } from './queries';

const Actions = styled(View)`
  flex-direction: row;
  gap: 20px;
  align-items: baseline;
`;

const Form = styled(View)`
  flex-direction: row;
  gap: 12px;
  align-items: baseline;
`;

const Field = styled(View)`
  flex-direction: row;
  align-items: baseline;
  gap: 6px;
`;

const Label = styled(Text)`
`;

const ButtonsLabel = styled(Text)`
  min-width: 100px;
  font-size: 24px;
  font-weight: 600;
`;

const PrintSetup = () => {
  const { id } = useParams();
  const { credentials } = useContext(ClientContext);
  const iframeRef = useRef();
  const navigate = useNavigate();
  const [tagSetup, setTagSetup] = useState('');
  const [summarySetup, setSummarySetup] = useState('');
  const [serials, setSerials] = useState('');

  const { data, ...dataState } = useQuery(ORDER_PRINT_SETUP, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const [printOrder] = useMutation(PRINT_ORDER, {
    onCompleted: () => navigate('/orders')
  });

  const setupIframe = blob => {
    const dataUrl = window.URL.createObjectURL(blob);
    iframeRef.current.setAttribute('src', dataUrl);
  };

  const preview = type => {
    let path = `pdf/${type}/${id}.pdf?`;

    if ('tag' === type) {
      path = `pdf/order/${id}.pdf?serials=${data.order.tags[0].serialNum}&`;
    } else if (serials) {
      path = `${path}serials=${serials}&`;
    }

    if ('summary' === type) {
      path = `${path}printer=${summarySetup}`;
    } else {
      path = `${path}printer=${tagSetup}`;
    }

    rest({
      path,
      options: { headers: { Accept: 'application/pdf' } },
      credentials,
    }).then(response => {
      response.blob().then(blob => setupIframe(blob));
    });
  };

  const print = types => {
    printOrder({ variables: { id, types, tagSetup, summarySetup, serials } });
  };

  useEffect(() => {
    if (data?.order) {
      preview('tag');
    }
  }, [data]);

  useEffect(() => {
    if (data?.printerSetups.tag[0] && !tagSetup) {
      setTagSetup(data.printerSetups.tag[0]);
      setSummarySetup(data.printerSetups.summary[0]);
    }
  }, [data]);

  return (
    <View style={{ height: '80vh' }}>
      <DataState data={data} {...dataState}>
        <Title>
          {data?.order.name} - {data?.order.quantity} Tags{' - '}
          {data?.order.tags[0].serialNumber}-
          {data?.order.tags[data.order.tags.length - 1].serialNumber}
        </Title>

        {data?.order.filledAt && (
          <Warning>
            <WarningText>
              🚩 Warning: This order has been marked as printed.
              Make sure to cancel the order on the printer if needed.
            </WarningText>
          </Warning>
        )}

        <Form>
          <Field>
            <Label>Tag Printer:</Label>
            <Picker
              selectedValue={tagSetup}
              onValueChange={
                value => { setTagSetup(value); setSummarySetup(value); }
              }
            >
              {data?.printerSetups.tag.map(option => (
                <Picker.Item key={option} value={option} label={option} />
              ))}
            </Picker>
          </Field>

          <Field>
            <Label>Summary Printer:</Label>
            <Picker
              selectedValue={summarySetup}
              onValueChange={value => setSummarySetup(value)}
            >
              {data?.printerSetups.summary.map(option => (
                <Picker.Item key={option} value={option} label={option} />
              ))}
            </Picker>
          </Field>

          <Field>
            <Label>Serials (leave blank for all):</Label>
            <StyledTextInput
              style={{ minWidth: 400 }}
              value={serials}
              onChangeText={setSerials}
            />
          </Field>

          {data?.order && (
            <Link to={`/orders/${data?.order.id}/edit`}>
              <Text>[Edit Order]</Text>
            </Link>
          )}
        </Form>

        <Actions>
          <ButtonsLabel>Preview</ButtonsLabel>
          <Button
            label="Tags"
            onPress={() => preview('order')}
            style={{ backgroundColor: 'blue' }}
          />
          <Button
            label="Summary"
            onPress={() => preview('summary')}
            style={{ backgroundColor: 'blue' }}
          />
        </Actions>

        <Actions>
          <ButtonsLabel>Print</ButtonsLabel>
          <Button label="Tags" onPress={() => print(['tags'])} />
          <Button label="Summary" onPress={() => print(['summary'])} />
          <Button
            label="Tags and Summary"
            onPress={() => print(['summary', 'tags'])}
          />
        </Actions>
      </DataState>

      <iframe ref={iframeRef} width="100%" height="100%"></iframe>
    </View>
  );
};

export default PrintSetup;
