import React from 'react';
import { View } from 'react-native';
import { Subtitle, Text } from 'app/src/styles';

import ExternalLink from 'app/src/elements/ExternalLink';

const About = () => {
  return (
    <View>
      <Text>
       oklahomatemptag.com is a portal for licensed Oklahoma auto dealers to
        pre-register vehicle sales in accordance with SB2035, also known as
        The Mason Treat Act. For more information on Pre-Registration or if you
        are a consumer, please visit:{' '}
        <ExternalLink url="http://readysettag.com">
          <Text>ReadySetTag.com</Text>
        </ExternalLink>
      </Text>

      <Subtitle>Who Can Use oklahomatemptag.com?</Subtitle>

      <Text>
        This website is a service for Oklahoma auto dealers who purchase their
        10-day temporary tags from{' '}
        <ExternalLink url="https://autodealersresource.com">
          <Text>Auto Dealers Resource</Text>
        </ExternalLink>{' '}
        or who are members of{' '}
        <ExternalLink url="https://www.okiada.com/">
          <Text>OIADA</Text>
        </ExternalLink>.
      </Text>
    </View>
  );
};

export default About;
