import React from 'react';
import {
  ActivityIndicator,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from 'react-native';

import Auth from './components/Auth';
import ErrorBoundary from './components/ErrorBoundary';
import { ClientProvider, ClientContext } from './contexts/ClientContext';

import Main from './Main';

const App = () => {
  return (
    <ErrorBoundary>
      <ClientProvider>
        <ClientContext.Consumer>
          {ctx => ctx.isLoggedIn ? (
            <ErrorBoundary>
              {ctx.apolloClient ? <Main /> : <ActivityIndicator size="large" />}
            </ErrorBoundary>
          ) : (
            <Auth />
          )}
        </ClientContext.Consumer>
      </ClientProvider>
    </ErrorBoundary>
  );
};

export default App;
