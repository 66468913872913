import React, { useContext, useEffect, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';

import EmailAndPhoneDoc from 'app/src/components/Docs/Tags/EmailAndPhone';
import ClientContext from 'app/src/contexts/ClientContext';
import { Loading } from 'app/src/elements/DataState';
import { useNavigate, useParams } from 'app/src/utils/routing';
import {
  Fields,
  defaultValues,
  prepareInput,
  EMAIL_PATTERN,
  PHONE_PATTERN,
  ZIP_CODE_PATTERN,
} from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import { Notice } from 'app/src/elements/Notice';
import Input from 'app/src/elements/inputs';
import { Text, Title, Subtitle, Row, Column } from 'app/src/styles';

import { DEALER } from 'app/src/components/Dealers/queries';
import { SEARCH } from 'app/src/components/Search/queries';

import {
  REGISTER_TAG,
  SEARCH_UNREGISTERED_SERIALS,
  SUGGEST_ADDRESS,
  VIN_DECODE
} from './queries';

const PRE_REGISTER_START_ON = '2024-09-01';
const PRE_REGISTER_START_STRING =
  DateTime.fromISO(PRE_REGISTER_START_ON).toLocaleString(DateTime.DATE_FULL);

const PRINT_OPTIONS = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'none',
    label: 'Pre-printed (just entering purchase data)',
  },
  {
    value: 'data',
    label: 'Print purchase data on pre-printed',
  },
  {
    value: 'blank',
    label: 'Print on blank sheet',
  },
];

const TYPE_OPTIONS = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'A',
    label: 'Automobile',
  },
  {
    value: 'M',
    label: 'Motorcycle',
  },
];

const TAG_FIELDS = {
  dealerId: {
    label: 'Dealer',
    clientSelect: 'activeDealers',
    oneOption: 'no-choice',
    rules: { required: true },
  },
  /* TODO Currently disabled because printing by dealer is not yet supported
  printOption: {
    label: 'Print Options',
    select: PRINT_OPTIONS,
    rules: { required: true },
  },
  */
  serialNumber: {
    label: 'Tag Number',
    rules: {
      required: true,
      maxLength: { value: 8, message: 'Maximum 8 characters' },
    },
  },
  soldOn: {
    label: 'Date Vehicle Sold and Tag Issued',
    type: 'date',
    rules: {
      required: true,
      validate: value => {
        if (value > DateTime.now().toISODate()) {
          return 'Must not be in the future';
        } else if (value < DateTime.now().minus({ days: 60 }).toISODate()) {
          return 'Must not be more than 60 days in the past';
        }
      }
    }
  },
};

export const VEHICLE_FIELDS = {
  vin: {
    label: 'VIN',
    rules: {
      required: true,
      pattern: {
        value: /^\w{17}$|^\w{6,7,8,13}$/,
        message: 'Must be 17 characters'
      },
    }
  },
  year: {
    rules: {
      required: true,
      pattern: { value: /^\d{4}$/, message: 'Must be 4 digits' },
    }
  },
  make: {
    clientSelect: 'makes',
    rules: { required: true },
  },
  model: { rules: { required: true } },
  color: { rules: { required: true } },
  style: { rules: { required: false } },
  type: {
    select: TYPE_OPTIONS,
    hint: 'Automobile or Motorcycle, based on tag size',
    rules: { required: true }
  },
  purchasePrice: {
    type: 'int',
    hint: 'Optional'
  },
  tradeInValue: {
    type: 'int',
    hint: 'Optional'
  },
};

export const PURCHASER_FIELDS = {
  purchaser: {
    label: 'Purchaser Name',
    rules: { required: true }
  },
  street: {
    label: 'Purchaser Street Address',
    rules: { required: true }
  },
  unitType: {
    label: 'Purchaser Unit Type',
    clientSelect: 'unitTypes'
  },
  unitNumber: {
    label: 'Purchaser Unit Number',
  },
  city: {
    label: 'Purchaser City',
    rules: { required: true }
  },
  state: {
    label: 'Purchaser State',
    rules: {
      required: true,
      maxLength: { value: 2, message: 'Use 2-character abbreviation' }
    }
  },
  zip: {
    label: 'Purchaser Zip Code',
    rules: {
      required: true,
      pattern: {
        value: ZIP_CODE_PATTERN,
        message: 'Enter 5-digit zip code or 9 digit zip+4',
      },
    }
  },
  email: {
    label: 'Purchaser Email',
    hint: 'Requested by Service Oklahoma, but not required',
    rules: {
      pattern: { value: EMAIL_PATTERN, message: 'Enter a valid email' },
    },
    docs: {
      title: 'Purhaser Email and Phone',
      element: EmailAndPhoneDoc,
    },
  },
  phone: {
    label: 'Purchaser Phone',
    hint: 'Requested by Service Oklahoma, but not required',
    rules: {
      pattern: {
        value: PHONE_PATTERN,
        message: 'Enter phone number in format ###-###-####'
      },
    },
  },
};

export const FIELDS = {
  ...TAG_FIELDS,
  ...PURCHASER_FIELDS,
  ...VEHICLE_FIELDS,
};

const NOT_REQUIRED_FOR_LOG = [
  'color', 'street', 'city', 'state', 'zip', 'email', 'phone',
];

export const extractFields = (original, modified) => {
  const extracted = {};

  Object.keys(original).forEach(key => extracted[key] = modified[key]);

  return extracted;
};

const NewTag = () => {
  const { currentUser, makes, isMobile } = useContext(ClientContext);
  const navigate = useNavigate();
  const params = useParams();
  const [enforcePreRegistration, setEnforcePreRegistration] = useState(false);
  const [tagType, setTagType] = useState(params.serialNumber ? 'adr' : null);
  const [vinDecodeError, setVinDecodeError] = useState();
  const [formStatus, setFormStatus] = useState();

  const {
    control, handleSubmit, setValue, watch, formState: { errors }
  } = useForm({
    defaultValues: {
      ...defaultValues({ fields: FIELDS }),
      dealerId: params.dealerId || '',
      serialNumber: params.serialNumber || '',
      type: params.type || '',
      keepTag: 'keep' === tagType,
      printOption: 'none',
      soldOn: DateTime.now().toISODate(),
    }
  });

  const values = watch();

  const [getDealerName] = useLazyQuery(DEALER, {
    onCompleted: data => {
      if (!values.dealerName) {
        setValue('dealerName', data.dealer.name);
      }
    }
  });

  useEffect(() => {
    if (params.dealerId) {
      getDealerName({ variables: { id: params.dealerId } });
    }
  }, []);

  const [decodeVin] = useLazyQuery(VIN_DECODE, {
    onCompleted: ({ vinDecode }) => {
      if (vinDecode) {
        if (vinDecode.error) {
          setVinDecodeError(vinDecode.error);
        } else {
          setVinDecodeError(null);
          setValue('year', vinDecode.year || '');
          setValue('make', vinDecode.make || '');
          setValue('model', vinDecode.model || '');
          setValue('style', vinDecode.style || '');

          if (!values.type) {
            setValue('type', vinDecode.type || '');
          }

          if (vinDecode.make) {
            setValue(
              'makeLabel',
              makes.find(make => make.value === vinDecode.make)?.label
            );
          }
        }
      }
    }
  });

  const [registerTag] = useMutation(REGISTER_TAG, {
    onCompleted: () => navigate('/')
  });

  useEffect(() => {
    if (17 === values.vin.length) {
      decodeVin({ variables: { vin: values.vin } });
    }
  }, [values.vin]);

  const onSubmit = input => {
    setFormStatus('submitting');
    registerTag({ variables: prepareInput(input, FIELDS) });
  };

  const updateTagType = value => {
    setValue('keepTag', 'keep' === value);
    setTagType(value);
  };

  const fields = { ...FIELDS };

  if (currentUser?.isEmployee) {
    fields.dealerId = { ...fields.dealerId };

    fields.dealerId.autocomplete = {
      query: SEARCH,
      queryName: 'search',
      variables: { type: 'Dealers' },
      selectedLabel: values.dealerName || '',
      setSelected: selected => {
        setValue('dealerId', selected?.id);
        setValue('dealerName', selected?.name);
      }
    };
  }

  if (values.dealerId && 'adr' === tagType) {
    fields.serialNumber = { ...fields.serialNumber };

    fields.serialNumber.autocomplete = {
      componentName: 'serialNumber',
      query: SEARCH_UNREGISTERED_SERIALS,
      queryName: 'searchUnregisteredSerials',
      variables: { dealerId: values.dealerId },
      matchResultAttribute: 'serialNumber',
      selectedLabel: values.serialNumber || '',
      setSelected: selected => {
        setValue('serialNumber', selected?.serialNumber);
        setValue('type', selected?.type);
      },
      updateTagType: updateTagType,
    };
  }

  if ('keep' !== tagType) {
    fields.serialNumber = {
      ...fields.serialNumber,
      rules: {
        required: true,
        minLength: { value: 8, message: 'Must be 8 characters' },
        maxLength: { value: 8, message: 'Must be 8 characters' },
      },
    };
  }

  fields.make = { ...fields.make };

  fields.make.autocomplete = {
    clientSelect: 'makes',
    selectedLabel: values.makeLabel || '',
    setSelected: selected => {
      setValue('make', selected?.value);
      setValue('makeLabel', selected?.label);
    }
  };

  if (currentUser?.featureAddressSuggest) {
    fields.street = {
      ...fields.street,
      type: 'addressStreet',
      inputProps: { dealerId: values.dealerId },
    };
  }

  const beforePreRegister = values.soldOn < PRE_REGISTER_START_ON;
  const logOnly = !enforcePreRegistration && beforePreRegister;

  Object.keys(fields).forEach(key => {
    if (logOnly && NOT_REQUIRED_FOR_LOG.includes(key)) {
      fields[key] = { ...fields[key] };
      fields[key].hint = 'Optional';
      if (fields[key].rules) {
        fields[key].rules = { ...fields[key].rules, required: false };
      }
    } else if (!fields[key].rules?.required && !fields[key].hint) {
      fields[key] = { ...fields[key], hint: 'Optional' };
    }
  });

  if (!tagType) {
    return (
      <View>
        <Title>Register Tag</Title>

        <Text>
          Is the customer keeping their metal tag or are you registering a
          10-day temporary tag?
        </Text>

        <Button label="Keep Metal Tag" onPress={() => updateTagType('keep')} />
        <Button label="Temporary Tag" onPress={() => updateTagType('adr')} />
        <Pressable onPress={() => updateTagType('other')}>
          <Text>Pre-Register Temporary Tag not purchased from ADR</Text>
        </Pressable>
      </View>
    );
  }

  return (
    <View>
      <Title>Register Tag</Title>

      <Fields
        fields={extractFields(TAG_FIELDS, fields)}
        control={control}
        errors={errors}
        setValue={setValue}
      />

      {beforePreRegister ? (
        <Text>
          The vehicle sold date is prior to {PRE_REGISTER_START_STRING}.
          It will not be pre-registered, and some fields that will be required
          for pre-registration are not required for logging the tag. If you
          would like to enforce the rules for pre-registration, check this
          box:{' '}
          <input
            type="checkbox"
            checked={enforcePreRegistration}
            onChange={e => setEnforcePreRegistration(e.target.checked)}
          />
        </Text>
      ) : (
        values.soldOn && (
          <View style={{ flexDirection: 'row', gap: 10 }}>
            <Text>10-Day Tag Expires:</Text>
            <Text style={{ fontWeight: 600 }}>
              {DateTime.fromISO(values.soldOn).plus({ days: 10 }).
                toFormat('LL/dd/yy')
              }
            </Text>
          </View>
        )
      )}

      <Row>
        <Column isMobile={isMobile}>
          <Subtitle>Vehicle Details</Subtitle>

          {vinDecodeError && <Notice type="error">{vinDecodeError}</Notice>}

          <Fields
            fields={extractFields(VEHICLE_FIELDS, fields)}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </Column>

        <Column isMobile={isMobile}>
          <Subtitle>Purchaser Details</Subtitle>

          <Fields
            fields={extractFields(PURCHASER_FIELDS, fields)}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </Column>
      </Row>

      {'submitting' === formStatus ? (
        <Loading />
      ) : (
        <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
      )}
    </View>
  );
};

export default NewTag;
