import { gql } from '@apollo/client';

import { NOTE_FIELDS } from 'app/src/components/Notes/queries';

const ERRORS = `errors { path, message }`;

export const ORDER_CORE_FIELDS = gql`
  fragment OrderCoreFields on Order {
    id
    quantity
    serialSummary
    template
    filledAt
    name
    shopifyOrderNumber
  }
`;

export const ORDER_FIELDS = gql`
  ${ORDER_CORE_FIELDS}
  ${NOTE_FIELDS}
  fragment OrderFields on Order {
    ...OrderCoreFields
    notes {
      ...NoteFields
    }
    dealer {
      id
      name
      notes {
        ...NoteFields
      }
    }
    product {
      id
      name
    }
    newLicense {
      id
      licenseString
      licenseType
      licenseNumber
    }
    usedLicense {
      id
      licenseString
      licenseType
      licenseNumber
    }
  }
`;

export const ORDER = gql`
  ${ORDER_FIELDS}
  query Order($id: ID!) {
    order(id: $id) {
      ...OrderFields
      canDestroy
      shopifyItem {
        orderNumber
        productName
        productId
        printedName
        licenseNumber
      }
    }
  }
`;

export const ORDER_PRINT_SETUP = gql`
  ${ORDER_FIELDS}
  query OrderPrintSetup($id: ID!) {
    order(id: $id) {
      ...OrderFields
      tags {
        serialNum
        serialNumber
      }
    }
    printerSetups {
      tag
      summary
    }
  }
`;

export const ORDERS = gql`
  ${ORDER_FIELDS}
  query Orders(
    $limit: Int!
    $offset: String!
    $options: ConnectionOptionsInput!
  ) {
    orders(
      first: $limit
      after: $offset
      options: $options
    ) {
      edges {
        cursor
        node {
          ...OrderFields
        }
      }
      totalCount
    }
  }
`;

const ORDER_ARGUMENTS = `
  $dealerId: ID!
  $productId: ID!
  $quantity: Int!
  $template: String!
  $newLicenseId: ID
  $usedLicenseId: ID
  $shopifyOrderNumber: String
  $note: String
`;

const ORDER_VARIABLES = `
  dealerId: $dealerId
  productId: $productId
  quantity: $quantity
  template: $template
  newLicenseId: $newLicenseId
  usedLicenseId: $usedLicenseId
  shopifyOrderNumber: $shopifyOrderNumber
  note: $note
`;

export const CREATE_ORDER = gql`
  ${ORDER_FIELDS}
  mutation CreateOrder (
    ${ORDER_ARGUMENTS}
  ) {
    createOrder (
      ${ORDER_VARIABLES}
    ) {
      order {
        ...OrderFields
      }
      ${ERRORS}
    }
  }
`;

export const UPDATE_ORDER = gql`
  ${ORDER_CORE_FIELDS}
  mutation UpdateOrder (
    $id: ID!
    ${ORDER_ARGUMENTS}
  ) {
    updateOrder (
      id: $id
      ${ORDER_VARIABLES}
    ) {
      order {
        ...OrderCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const DESTROY_ORDER = gql`
  mutation DestroyOrder (
    $id: ID!
    $note: String!
    $physicalTags: String!
  ) {
    destroyOrder (
      id: $id
      note: $note
      physicalTags: $physicalTags
    ) {
      ${ERRORS}
    }
  }
`;

export const PRINT_ORDER = gql`
  ${ORDER_CORE_FIELDS}
  mutation PrintOrder (
    $id: ID!
    $types: [String!]!
    $tagSetup: String
    $summarySetup: String
    $serials: String
  ) {
    printOrder (
      id: $id
      types: $types
      tagSetup: $tagSetup
      summarySetup: $summarySetup
      serials: $serials
    ) {
      order {
        ...OrderCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const COMMISSION_DATA = gql`
  query CommissionData(
    $licenseType: String!
    $licenseNumber: String!
    $asOf: ISO8601DateTime
  ) {
    commissionData(
      licenseType: $licenseType
      licenseNumber: $licenseNumber
      asOf: $asOf
    ) {
      name
      licenseString
      status
      licenseType
      licenseDate
      phone
      email
      url
      address1
      address2
      city
      state
      country
      zip
      county
      viewUrl
      updatedAt
      body
    }
  }
`;

export const SHOPIFY_ITEMS = gql`
  ${ORDER_CORE_FIELDS}
  query ShopifyItems {
    shopifyItems {
      orderNumber
      productName
      productId
      printedName
      licenseNumber
      order {
        ...OrderCoreFields
      }
    }
  }
`;

export const UPCOMING_REORDERS = gql`
  query UpcomingReorders (
    $lowUnregisteredThreshold: Float
    $pastDataDaysAgo: Int
    $pastDataIgnoreThreshold: Float
  ) {
    upcomingReorders {
      lowUnregistered (
        threshold: $lowUnregisteredThreshold
      ) {
        id
        filledAt
        quantity
        registeredCount
        dealer { id, name }
      }
      pastData (
        daysAgo: $pastDataDaysAgo
        ignoreThreshold: $pastDataIgnoreThreshold
      ) {
        firstOrderCount
        noRegistrationsCount
        belowThresholdCount
        aboveThresholdCount
        averageRegistrationsPercent
      }
    }
  }
`;
