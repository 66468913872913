import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import { useMutation } from '@apollo/client';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import User from 'app/src/components/Users/show';
import EditableDetails from 'app/src/elements/EditableDetails';
import { Text } from 'app/src/styles';

const UserDealer = ({ userDealer }) => {
  return (
    <View key={userDealer.id} style={{ marginBottom: 10 }}>
      <EditableDetails
        modalContent={User}
        title={userDealer.email}
        id={userDealer.userId}
        icon={faEye}
        canEdit
      >
        <Text>User Name: {userDealer.fullName}</Text>
        <Text>Email: {userDealer.email}</Text>
        {userDealer.admin && <Text style={{ fontWeight: 'bold' }}>Admin</Text>}
      </EditableDetails>
    </View>
  );
};

const Users = ({ dealer }) => {
  return (
    <>
      {dealer.userDealers.map(userDealer => (
        <UserDealer key={userDealer.id} userDealer={userDealer} />
      ))}
    </>
  );
};

export default Users;
