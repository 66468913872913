import React from 'react';
import { View } from 'react-native';
import { useQuery } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';

import { Loading } from 'app/src/elements/DataState';
import { Title } from 'app/src/styles';
import { ActionText } from 'app/src/styles/table';
import { Link } from 'app/src/utils/routing';

import { DEALERS_NO_RECENT_ORDER } from './queries';

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'licenses',
    label: 'License #s',
    sort: 'prevent',
    value: ({ value }) => value.map(license => (
      `${license.licenseType}${license.licenseNumber}`
    )).join(', '),
  },
  {
    key: 'activeMember',
    label: 'Member?',
    value: ({ value }) => value ? 'Yes' : ''
  },
  {
    key: 'registeredTagCount',
    label: 'Tags Registered',
  },
  {
    key: 'functions',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <View style={{ flexDirection: 'row', gap: 10 }}>
        <Link to={`/dealers/${entry.id}`}>
          <ActionText>View</ActionText>
        </Link>
        <Link to={`/dealers/${entry.id}/edit`}>
          <ActionText>Edit</ActionText>
        </Link>
      </View>
    )
  },
];

const NoRecentOrder = () => {
  const { data } = useQuery(DEALERS_NO_RECENT_ORDER);

  if (!data) { return <Loading />; }

  return (
    <View>
      <Title>Dealers who have signed up but no order in past year</Title>

      <DataTable.Simple
        pageLength={100}
        data={data.dealersNoRecentOrder}
        columns={columns}
      />
    </View>
  );
};

export default NoRecentOrder;
